import { Avatar as MuiAvatar, AvatarProps } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import styled from '../styled';

export const AVATAR_SIZES = {
  small: 36,
  normal: 44
};

export type AvatarSize = 'small' | 'normal';

const AvatarSmall = styled(MuiAvatar)((p) => ({
  height: AVATAR_SIZES.small,
  width: AVATAR_SIZES.small,

  img: {
    height: AVATAR_SIZES.small,
    width: AVATAR_SIZES.small
  }
}));

export const Avatar = ({
  size,
  ...p
}: AvatarProps & {
  size?: AvatarSize;
}) => {
  if (size === 'small') {
    return <AvatarSmall {...p} />;
  }
  return <MuiAvatar {...p} />;
};

export const AvatarLoader = ({ size = 'normal' }: { size?: AvatarSize }) => {
  const s = AVATAR_SIZES[size];
  return <Skeleton variant="circle" width={s} height={s} />;
};
