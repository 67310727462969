// pass in options and pass in renderers
// all optional

import { createElement, useMemo } from 'react';
import { LinkExternal } from './LinkExternal';

type AutoLinkTextMatcher = {
  key: string;
  regexp: RegExp;
  renderer: (m: RegExpMatchArray) => React.ReactNode;
};

const URL_MATCHER: AutoLinkTextMatcher = {
  key: 'a',
  regexp: /((?:https?:\/\/.)?(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b(?:[-a-zA-Z0-9@:%_\+.~#?&//=]*))/,
  renderer: (m) => {
    const url = m[0].startsWith('http') ? m[0] : `https://${m[0]}`;
    const lowerUrl = url.toLowerCase();
    return (
      <LinkExternal href={lowerUrl} title={lowerUrl} underline>
        {m[0]}
      </LinkExternal>
    );
  }
};

export const AutoLinkText = ({
  component = 'div',
  text
}: {
  component?: React.ComponentType | string;
  text: string;
}) => {
  const parts: React.ReactNode[] = useMemo(() => {
    return text.split(URL_MATCHER.regexp).map((part) => {
      const m = part.match(URL_MATCHER.regexp);
      if (m) {
        return URL_MATCHER.renderer(m);
      }
      return part;
    });
  }, [text]);

  return createElement(component, {}, ...parts);
};
