import { FS } from '../constants';
import { uniq, flatten } from 'lodash';
import { DateTime } from 'luxon';
import { useCollection } from 'react-firebase-hooks/firestore';
import { UserArticle } from '../types/UserArticle';
import { generateToDocFn, Doc } from '../types/Document';
import { store, useMappedLoadingValue } from './db';
import {
  createSingleCollectionListenerStore,
  CollectionListener,
  useCollectionListener
} from './collectionListener';
import { fromMillis } from './time';
import { Website } from '../types/Website';

export const toUserArticleDoc = generateToDocFn<UserArticle>();
export const userArticlesCollection = () => store().collection(FS.userArticles);

const getUserArticleListener = createSingleCollectionListenerStore(
  () => new CollectionListener(userArticlesCollection(), toUserArticleDoc)
);

export const THIS_MONTH = DateTime.local().startOf('month').toMillis();

export const THIRTY_DAYS_AGO = DateTime.local()
  .minus({ days: 30 })
  .startOf('day')
  .toMillis();

export const SEVEN_DAYS_AGO = DateTime.local()
  .minus({ days: 7 })
  .startOf('day')
  .toMillis();

export const userIdsWhoPublished = ({
  articles,
  websites,
  limitInMillis
}: {
  articles: Doc<UserArticle>[];
  websites: Doc<Website>[];
  limitInMillis: number;
}) => {
  const recentArticles = articles.filter((article) => {
    const publishedMillis = article.data.publishedAt?.toMillis();
    if (!publishedMillis) {
      return false;
    }
    return publishedMillis > limitInMillis;
  });

  const websiteIdsWithRecentArticles = recentArticles.map(
    (a) => a.data.websiteId
  );

  return uniq(
    flatten(
      websites
        .filter((website) => websiteIdsWithRecentArticles.includes(website.id))
        .map((website) => website.data.userIds)
    )
  );
};

export const useRecentUserArticles = (
  {
    limit = 10,
    publishedSince = THIRTY_DAYS_AGO
  }: { limit: number; publishedSince: number } = {
    limit: 10,
    publishedSince: THIRTY_DAYS_AGO
  }
) => {
  return useMappedLoadingValue(
    useCollection(
      userArticlesCollection()
        .where('publishedAt', '>=', fromMillis(publishedSince))
        .orderBy('publishedAt', 'desc')
        .limit(limit)
    ),
    (s) => s.docs.map(toUserArticleDoc)
  );
};

export const useUserArticles = () => {
  return useCollectionListener(getUserArticleListener());
};
