import { firestore } from 'firebase/app';

export type BillingUser = {
  customerId: string;
  subscriptionId: string;
  plan: 'pro' | null;
};

export enum UserRole {
  ADMIN = 'ADMIN',
  MODERATOR = 'MODERATOR'
}

export type User = {
  email: string;
  firstName: string;
  lastName: string;
  joinDate: firestore.Timestamp;
  location: string;
  headline: string;
  bio: string;
  avatarUrl: string;
  circleUserId: number | null;
  circlePublicUid: string;
  circleProfileUrl: string;
  twitterUrl: string;

  roles: UserRole[];

  settings: {
    showEmail: boolean;
  };
};

export type UserPublic = Omit<User, 'settings'>;

export type CurrentUser = {
  id: string;
  authUser: firebase.User;
  user: User;
  billingUser: BillingUser;
  isAdmin: boolean;
};
