import { Avatar, Paper, Tooltip, Typography } from '@material-ui/core';
import { compact, flatten, uniq } from 'lodash';
import { DateTime } from 'luxon';
import React, { useMemo } from 'react';
import { Star } from 'react-feather';
import { Branding } from '../components/Branding';
import { onlyOnClientSide } from '../components/ClientSidePage';
import { CountBadge } from '../components/CountBadge';
import { ProductHuntBanner } from '../components/ProductHuntBanner';
import { SEO } from '../components/SEO';
import { PrimarySubheading, Subheading } from '../components/Subheading';
import { UserAvatar } from '../components/UserAvatar';
import Layout, { Wrapper } from '../layouts/Layout';
import { useCurrentUser } from '../services/currentUser';
import { EMPTY_ARR } from '../services/emptyConstants';
import {
  SEVEN_DAYS_AGO,
  THIRTY_DAYS_AGO,
  THIS_MONTH,
  useRecentUserArticles,
  userIdsWhoPublished
} from '../services/userArticles';
import { toUserName, useUserPublicMapByIds } from '../services/usersPublic';
import { toPrettyWebsiteUrl, useWebsites } from '../services/website';
import styled from '../styled';
import { Doc } from '../types/Document';
import { UserPublic } from '../types/User';
import { UserArticle } from '../types/UserArticle';
import { Website } from '../types/Website';

const FeedLayout = styled('div')`
  display: grid;
  grid-template-columns: 2fr 1fr;

  @media (max-width: 600px) {
    grid-template-columns: 1fr;
  }
`;

const Text = styled('p')`
  color: ${(p) => p.theme.palette.grey['A200']};
  max-width: 500px;
  margin: 0 auto;
  font-size: ${(p) => p.theme.typography.body1.fontSize};
  line-height: ${(p) => p.theme.typography.body1.lineHeight};

  a {
    border-bottom: 1px solid ${(p) => p.theme.palette.grey['A200']};
  }
`;

const AuthorAvatar = styled(UserAvatar)`
  margin-right: ${(p) => p.theme.spacing(1)}px;
  transition: 0.2s border-color ease-in;
`;

const AuthorLink = styled('a')`
  display: flex;
  align-items: center;
  margin-right: 6px;
  font-weight: 600;
  position: relative;

  &:hover .MuiAvatar-root {
    border-color: ${(p) => p.theme.palette.grey[500]};
  }
`;

const StarWrapper = styled('div')`
  width: 18px;
  height: 18px;
  background-color: #ffc53d;
  border-radius: 100px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ad6800;
  bottom: 0;
  right: 0;
`;

const ArticleMeta = styled('div')`
  opacity: 0;
  transition: 0.2s opacity ease-in;
  display: flex;
  align-items: center;
`;

const WebsiteMeta = styled('span')`
  color: ${(p) => p.theme.palette.grey[400]};
`;

const WebsiteLink = styled('a')``;

const AuthorAndWebsite = ({
  website,
  user
}: {
  website: Doc<Website> | null;
  user: Doc<UserPublic> | null;
}) => {
  return (
    <ArticleMeta style={{ opacity: user && website ? 1 : 0 }}>
      {user && (
        <AuthorLink href={user.data.circleProfileUrl}>
          <AuthorAvatar user={user.data} size="small"></AuthorAvatar>
          {toUserName(user.data)}
        </AuthorLink>
      )}
      {user && website && (
        <>
          <WebsiteMeta>
            on{' '}
            <WebsiteLink href={website.data.url} target="_blank" rel="nofollow">
              {website.data.name || toPrettyWebsiteUrl(website.data.url)}
            </WebsiteLink>
          </WebsiteMeta>
        </>
      )}
    </ArticleMeta>
  );
};

type SiteMap = { [websiteId: string]: Doc<Website> };
type UserPublicMap = { [userId: string]: Doc<UserPublic> | null };

const ArticleWrapper = styled('div')`
  margin-bottom: ${(p) => p.theme.spacing(4)}px;
`;

const ArticleLink = styled('a')`
  display: inline-block;
  border-bottom: 1px solid;
  margin-bottom: ${(p) => p.theme.spacing(1)}px;
`;

const RelativeDate = styled('div')`
  color: ${(p) => p.theme.palette.grey[400]};
  font-style: italic;
  margin-left: 42px;
`;

const Article = ({
  article,
  user,
  website
}: {
  article: Doc<UserArticle>;
  user: Doc<UserPublic> | null;
  website: Doc<Website> | null;
}) => {
  const date = new Date(article.data.publishedAt!.toDate());
  const relativeDate = DateTime.fromJSDate(date).toRelative();

  return (
    <ArticleWrapper key={article.id}>
      <div>
        <ArticleLink href={article.data.url} target="_blank" rel="nofollow">
          {article.data.title}
        </ArticleLink>
      </div>
      <AuthorAndWebsite user={user} website={website} />
      <RelativeDate>{relativeDate}</RelativeDate>
    </ArticleWrapper>
  );
};

const RecentPublishersAvatarsWrapper = styled('div')`
  margin-top: ${(p) => p.theme.spacing(1)}px;
`;

const RecentPublishersAvatars = ({
  articles,
  usersById,
  websites,
  publishedMillis
}: {
  articles: Doc<UserArticle>[];
  usersById: UserPublicMap;
  websites: Doc<Website>[];
  publishedMillis: number;
}) => {
  const users = Object.values(usersById);
  const publisherIds = userIdsWhoPublished({
    articles,
    websites,
    limitInMillis: publishedMillis
  });
  const publishers = compact(
    users.filter((u) => u && publisherIds.includes(u.id))
  );

  return (
    <RecentPublishersAvatarsWrapper>
      {publishers.map((publisher) => (
        <Tooltip key={publisher!.id} title={toUserName(publisher.data)}>
          <div style={{ display: 'inline-block', position: 'relative' }}>
            <Avatar src={publisher.data.avatarUrl}>
              {publisher.data.firstName.slice(0, 1).toUpperCase()}
            </Avatar>
            <StarWrapper>
              <Star size={12} />
            </StarWrapper>
          </div>
        </Tooltip>
      ))}
    </RecentPublishersAvatarsWrapper>
  );
};

const RecentPublishers = ({
  articles,
  usersById,
  websites
}: {
  articles: Doc<UserArticle>[];
  usersById: UserPublicMap | void;
  websites: Doc<Website>[] | void;
}) => {
  if (!usersById || !websites) {
    return null;
  }

  return (
    <div>
      <Subheading paragraph>Shout out to these devs</Subheading>
      <Typography variant="body1" component="p" paragraph>
        Here's who's published in the last 30 days! 🥳
      </Typography>
      <br />
      <RecentPublishersContainer>
        <Subheading>Last 7 days</Subheading>
        <RecentPublishersAvatars
          articles={articles}
          usersById={usersById}
          websites={websites}
          publishedMillis={SEVEN_DAYS_AGO}
        />
      </RecentPublishersContainer>
      <RecentPublishersContainer>
        <Subheading>This month</Subheading>
        <RecentPublishersAvatars
          articles={articles}
          usersById={usersById}
          websites={websites}
          publishedMillis={THIS_MONTH}
        />
      </RecentPublishersContainer>
      <RecentPublishersContainer>
        <Subheading>Last 30 days</Subheading>
        <RecentPublishersAvatars
          articles={articles}
          usersById={usersById}
          websites={websites}
          publishedMillis={THIRTY_DAYS_AGO}
        />
      </RecentPublishersContainer>
    </div>
  );
};

const RecentPublishersContainer = styled(Paper)`
  padding: ${(p) => p.theme.spacing(2)}px ${(p) => p.theme.spacing(3)}px;
  margin-bottom: ${(p) => p.theme.spacing(3)}px;
`;

const filterArticles = (start: number, end: number) => (
  article: Doc<UserArticle>
) => {
  const date = article.data.publishedAt!.toMillis();
  return date < start && date >= end;
};

const Feed = ({ articles }: { articles: Doc<UserArticle>[] }) => {
  const websiteIdsWithRecentArticles = useMemo(
    () => articles.map((a) => a.data.websiteId),
    [articles]
  );

  const [
    todayArticles,
    yesterdayArticles,
    lastSevenDaysArticles,
    moreArticles
  ] = useMemo(() => {
    const today = articles.filter(filterArticles(NOW, TODAY));
    const yesterday = articles.filter(
      filterArticles(YESTERDAY, YESTERDAY_MORNING)
    );
    const lastSevenDays = articles.filter(
      filterArticles(YESTERDAY_MORNING, SEVEN_DAYS_AGO)
    );
    const more = articles.filter(
      filterArticles(SEVEN_DAYS_AGO, THIRTY_DAYS_AGO)
    );
    return [today, yesterday, lastSevenDays, more];
  }, [articles]);

  const [websites] = useWebsites();

  const [userWebsites, userIds] = useMemo(() => {
    if (!websites) {
      return [null, EMPTY_ARR];
    }

    const sites = websites.reduce<SiteMap>((memo, item) => {
      memo[item.id] = item;
      return memo;
    }, {});

    const ids = uniq(
      flatten(
        websites
          .filter((website) =>
            websiteIdsWithRecentArticles.includes(website.id)
          )
          .map((website) => website.data.userIds)
      )
    );

    return [sites, ids];
  }, [websites]);

  const [users] = useUserPublicMapByIds(userIds);

  const renderArticle = (article: Doc<UserArticle>) => {
    const websiteId = article.data.websiteId;
    const website = userWebsites ? userWebsites[websiteId] : null;
    const websiteUserId = website ? website.data.userIds[0] : null;
    const websiteUser = users && websiteUserId ? users[websiteUserId] : null;

    return (
      <Article
        key={article.id}
        article={article}
        user={websiteUser}
        website={website}
      />
    );
  };

  return (
    <FeedLayout>
      <div>
        {todayArticles.length > 0 && (
          <>
            <PrimarySubheading>
              Today so far <CountBadge>{todayArticles.length}</CountBadge>
            </PrimarySubheading>
            <br />
            <div>{todayArticles.map(renderArticle)}</div>
          </>
        )}
        {yesterdayArticles.length > 0 && (
          <>
            <PrimarySubheading>
              Yesterday <CountBadge>{yesterdayArticles.length}</CountBadge>
            </PrimarySubheading>
            <br />
            <div>{yesterdayArticles.map(renderArticle)}</div>
          </>
        )}
        {lastSevenDaysArticles.length > 0 && (
          <>
            <PrimarySubheading>
              Last 7 days{' '}
              <CountBadge>{lastSevenDaysArticles.length}</CountBadge>
            </PrimarySubheading>
            <br />
            <div>{lastSevenDaysArticles.map(renderArticle)}</div>
          </>
        )}
        {moreArticles.length > 0 && (
          <>
            <PrimarySubheading>
              Last 30 days
              <CountBadge>{moreArticles.length}</CountBadge>
            </PrimarySubheading>
            <br />
            <div>{moreArticles.map(renderArticle)}</div>
          </>
        )}
      </div>
      <div>
        <RecentPublishers
          articles={articles}
          websites={websites}
          usersById={users}
        />
      </div>
    </FeedLayout>
  );
};

const NOW = DateTime.local().toMillis();
const TODAY = DateTime.local().startOf('day').toMillis();
const YESTERDAY = DateTime.local().minus({ days: 1 }).endOf('day').toMillis();
const YESTERDAY_MORNING = DateTime.local()
  .minus({ days: 1 })
  .startOf('day')
  .toMillis();

const IndexPage = () => {
  const { isAuthenticated } = useCurrentUser();
  const [articles] = useRecentUserArticles({
    limit: 100,
    publishedSince: THIRTY_DAYS_AGO
  });

  return (
    <Layout>
      <ProductHuntBanner />
      <SEO
        title="Member Feed"
        siteUrl="https://bloggingfordevs.com"
        description="See who's publishing lately and find out how you can support their work."
        pathname="feed/"
      />
      <Wrapper>
        <div
          style={{
            margin: '0 auto',
            textAlign: 'center'
          }}
        >
          {!isAuthenticated && <Branding to="/">Blogging for Devs</Branding>}
          <Typography variant="h4" component="h1" gutterBottom>
            Member Feed
          </Typography>
          <Text>Recently published posts by members of the community.</Text>
        </div>
      </Wrapper>
      <Wrapper style={{ maxWidth: '1000px' }}>
        {articles ? <Feed articles={articles} /> : null}
      </Wrapper>
    </Layout>
  );
};

export default onlyOnClientSide(IndexPage);
