import styled from '../styled';

export const CountBadge = styled('span')`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${(p) => p.theme.palette.primary.main};
  color: ${(p) => p.theme.palette.grey[100]};
  border-radius: 3px;
  padding: 0px 6px 0 8px;
  font-size: 10px;
  height: 20px;
  margin-left: ${(p) => p.theme.spacing(1)}px;
`;
