import { Tooltip, Typography } from '@material-ui/core';
import { Skeleton } from '@material-ui/lab';
import React, { createElement, useMemo } from 'react';
import { Icon, Twitter, User as IconUser } from 'react-feather';
import { EMPTY_ARR } from '../services/emptyConstants';
import { randomBetween } from '../services/random';
import { toUserName, useUserPublicById } from '../services/usersPublic';
import styled from '../styled';
import { UserPublic, UserRole } from '../types/User';
import { AutoLinkText } from './AutoLink';
import { Avatar, AvatarLoader, AvatarSize } from './Avatar';

const Container = styled('div')((p) => ({
  display: 'flex',
  alignItems: 'center',
  '> :not(:first-of-type)': {
    marginLeft: p.theme.spacing(1)
  }
}));

const RightContainer = styled('div')((p) => ({
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  '> :not(:first-of-type)': {
    marginTop: p.theme.spacing(0.5)
  }
}));

const TwitterLink = styled('a')((p) => ({
  display: 'inline-block',
  position: 'relative',
  top: '4px',
  marginLeft: p.theme.spacing(1)
}));

const HiddenOnMobile = styled('div')`
  display: block;

  @media (max-width: 600px) {
    display: none !important;
  }
`;

const VisibleOnMobile = styled('div')`
  display: none;

  @media (max-width: 600px) {
    display: block;
  }
`;

const Name = styled('div')((p) => ({
  display: 'flex',
  alignItems: 'center',
  fontWeight: p.theme.typography.fontWeightBold,
  fontSize: p.theme.custom.fontSize.l,
  '@media (max-width: 600px)': {
    display: 'block',
    '.bfd-badges > div': {
      marginLeft: `0 !important`
    },
    '.bfd-badges > :not(:first-of-type)': {
      marginLeft: `${p.theme.spacing(1)}px !important`
    }
  },
  div: {
    display: 'flex',
    alignItems: 'center'
  },
  '.bfd-badges div': {
    marginLeft: p.theme.spacing(1)
  },
  '.bfd-badges :not(:first-of-type)': {
    marginRight: p.theme.spacing(1)
  }
}));

export type BadgeProps = {
  text: string;
  color: 'blue' | 'gray' | 'yellow';
  variant: 'outlined' | 'contained';
};

const Badge = styled<
  'div',
  {
    color: BadgeProps['color'];
    variant: BadgeProps['variant'];
  }
>('div')((p) => {
  const [color1, color2] = (() => {
    if (p.color === 'blue') {
      return [
        p.theme.custom.colors.primary.dark,
        p.theme.custom.colors.primary.contrastText
      ];
    }
    if (p.color === 'yellow') {
      return ['#ffc53d', '#AD6800'];
    }
    if (p.color === 'gray') {
      return [p.theme.palette.grey[400], '#fff'];
    }
  })();
  const color = p.variant === 'contained' ? color2 : undefined;
  const backgroundColor = p.variant === 'contained' ? color1 : undefined;
  const borderColor = color1;
  return {
    fontSize: '0.625rem',
    textTransform: 'uppercase',
    padding: p.theme.spacing(0.5),
    color,
    backgroundColor,
    border: `1px solid ${borderColor}`,
    borderRadius: 3,
    boxSizing: 'border-box'
  };
});

export const AvatarWithNameAndMetadataLoader = React.memo(
  ({ size }: { size?: AvatarSize }) => {
    return (
      <Container>
        <AvatarLoader size={size} />
        <RightContainer>
          <Name>
            <Skeleton variant="rect" width={randomBetween(80, 120)} />
          </Name>

          <Typography variant="subtitle2" component="div">
            <Skeleton variant="rect" width={randomBetween(70, 120)} />
          </Typography>
        </RightContainer>
      </Container>
    );
  }
);

export const AvatarWithNameAndMetadata = ({
  name,
  avatarUrl,
  avatarIcon,
  metadata,
  badges = EMPTY_ARR,
  nextToName,
  size
}: {
  name: string;
  avatarUrl?: string;
  avatarIcon?: Icon;
  metadata: React.ReactNode;
  badges?: BadgeProps[];
  nextToName?: React.ReactNode;
  size?: AvatarSize;
}) => {
  return (
    <Container>
      {avatarUrl && <Avatar src={avatarUrl} size={size} />}
      {avatarIcon && <Avatar size={size}>{createElement(avatarIcon)}</Avatar>}
      {!avatarUrl && !avatarIcon && <Avatar>{name[0]}</Avatar>}
      <RightContainer>
        <Name>
          <div>{name}</div>
          <div className="bfd-badges">
            {badges.map((b, i) => (
              <Badge key={i} color={b.color} variant={b.variant}>
                {b.text}
              </Badge>
            ))}
          </div>
          <HiddenOnMobile>{nextToName}</HiddenOnMobile>
        </Name>
        <Typography variant="subtitle2" component="div">
          {metadata}
        </Typography>
        <VisibleOnMobile>{nextToName}</VisibleOnMobile>
      </RightContainer>
    </Container>
  );
};

const PRO_BADGE: BadgeProps = {
  text: 'Pro Member',
  color: 'blue',
  variant: 'contained'
};
const ADMIN_BADGE: BadgeProps = {
  text: 'Admin',
  color: 'blue',
  variant: 'outlined'
};
const GUEST_BADGE: BadgeProps = {
  text: 'Guest',
  color: 'gray',
  variant: 'outlined'
};

export const UserAvatarWithNameAndMetadata = ({
  user,
  additionalBadges = EMPTY_ARR,
  nextToName,
  size
}: {
  user: UserPublic;
  additionalBadges?: BadgeProps[];
  nextToName?: React.ReactNode;
  size?: AvatarSize;
}) => {
  const isAdmin = user.roles.indexOf(UserRole.ADMIN) !== -1;
  const badges = useMemo(
    () =>
      isAdmin
        ? [ADMIN_BADGE, PRO_BADGE, ...additionalBadges]
        : [PRO_BADGE, ...additionalBadges],
    [isAdmin, additionalBadges]
  );
  return (
    <AvatarWithNameAndMetadata
      name={toUserName(user)}
      metadata={
        user.headline && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <AutoLinkText text={user.headline} />
            {user.twitterUrl.length > 0 && (
              <TwitterLink
                href={user.twitterUrl}
                target="_blank"
                rel="nofollow noopener"
              >
                <Twitter size={16} />
              </TwitterLink>
            )}
          </div>
        )
      }
      avatarUrl={user.avatarUrl}
      badges={badges}
      nextToName={nextToName}
      size={size}
    />
  );
};

export const GuestAvatarWithNameAndMetadata = ({
  name,
  website,
  additionalBadges = EMPTY_ARR,
  nextToName,
  size
}: {
  name: string;
  website: string;
  additionalBadges?: BadgeProps[];
  nextToName?: React.ReactNode;
  size?: AvatarSize;
}) => {
  const badges = useMemo(() => [GUEST_BADGE, ...additionalBadges], [
    additionalBadges
  ]);
  return (
    <AvatarWithNameAndMetadata
      avatarIcon={IconUser}
      name={name}
      metadata={
        website ? (
          <a
            href={website}
            style={{ textDecoration: 'underline' }}
            rel="nofollow noopener"
          >
            {website}
          </a>
        ) : (
          <div> </div>
        )
      }
      badges={badges}
      nextToName={nextToName}
      size={size}
    />
  );
};

export const UserAvatar = ({
  user,
  size = 'normal',
  className
}: {
  user: UserPublic;
  size?: AvatarSize;
  className?: string;
}) => {
  return (
    <Avatar
      src={user.avatarUrl}
      alt={toUserName(user)}
      size={size}
      className={className}
    />
  );
};

export const UserAvatarLazy = ({
  userId,
  size = 'normal',
  className
}: {
  userId: string;
  size?: AvatarSize;
  className?: string;
}) => {
  const [user] = useUserPublicById(userId);
  if (user === null) {
    return <Avatar size={size} />;
  }
  if (!user) {
    return <AvatarLoader size={size} />;
  }

  return (
    <Tooltip title={toUserName(user.data)}>
      <Avatar
        src={user.data.avatarUrl}
        alt={toUserName(user.data)}
        size={size}
        className={className}
      />
    </Tooltip>
  );
};

export const UserName = ({ userId }: { userId: string }) => {
  const [user] = useUserPublicById(userId);
  const u = user || null;
  return <div>{u && toUserName(u.data)}</div>;
};
